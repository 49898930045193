export const environment = {
  production: true,

  // UAT IFMA
  baseUrl: 'https://admin.ifma-sat.uatbyopeneyes.com',
  ssoLoginUrl: 'https://loginifmaorg-sandbox.azurewebsites.net/?redirectUrl=',
  ssoLogoutUrl: 'https://loginifmaorg-sandbox.azurewebsites.net/Account/LogOff/?redirectUrl=',
  apiUrl: 'https://api.ifma-sat.uatbyopeneyes.com/public/api',
  covidUrl: 'https://covid.ifma-sat.uatbyopeneyes.com',
  dcmUrl: 'https://sat.ifma-sat.uatbyopeneyes.com',
  connectorUrl: 'https://connector.ifma-sat.uatbyopeneyes.com',
  authUrl: 'https://mysat.ifma-sat.uatbyopeneyes.com',
  authUrlReal: 'https://myassessments.ifma-sat.uatbyopeneyes.com',
  cndURL: 'https://assets.ifma-sat.uatbyopeneyes.com/',
  CookieDomainUrl: 'ifma-sat.uatbyopeneyes.com',
  TestMode: true,
  env: 'prod',
};
