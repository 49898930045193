<div class="loader_block" *ngIf="globals.isLoading" >
    <div class="height_loader">
      <div class="img_loader">
        <img src="{{globals.cndURL}}assets/images/loader.png" alt="" class="img-fluid" />
        <div class="loader">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
</div>
<div [ngClass]="{'admin_wrapper':globals.currentLink!='/landing-dashboard', 'covid_product_block':globals.ProductId==globals.ProductIdArray[1], 'dcm_product_block':globals.ProductId==globals.ProductIdArray[2]}" >
    <app-header *ngIf="globals.authData!=null && globals.currentLink!='/landing-dashboard'"></app-header>
    <app-side-menu *ngIf="globals.authData!=undefined && globals.currentLink!='/landing-dashboard'"></app-side-menu>
    <div class="right_wrapper">
        <app-breadcrumbs *ngIf="globals.authData!=undefined && globals.currentLink!='/landing-dashboard' && globals.institute_details!=null"></app-breadcrumbs>
        <router-outlet></router-outlet>
        <app-footer *ngIf="globals.authData!=null && globals.currentLink!='/landing-dashboard'"></app-footer>
    </div>
</div>
